<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="6">
        <v-card max-width="600px" flat>
          <v-card-title class="justify-center text-h5">
            {{ $t("__application_submit_successfully") }}
            <v-icon x-large color="success"
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </v-card-title>
          <v-card-text class="text-center text-h5 black--text">
            {{ $t("__application_wait_for_check") }}
          </v-card-text>
          <v-card-text class="text-center">
            {{ $t("__redirect_page_in_5s") }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "ApplySuccessPage",
  methods: {
    redirectPage() {
      this.$store.dispatch("setIsLoading", false);
      setTimeout(() => {
        this.$router.replace({
          name: "CreateStoreApplicationForms"
        });
      }, 5000);
    }
  },
  created() {
    this.redirectPage();
  }
};
</script>
